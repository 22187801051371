import 'focus-visible';
import 'fslightbox';
import Choices from 'choices.js';

function classNames(...objs) {
  return objs.filter((obj) => obj != null && obj !== false).join(' ');
}

function setupQuickLinks() {
  const element = document.getElementById('quick-links');
  if (!element) {
    return;
  }

  $(element).on('change', function (event) {
    document.location = event.target.value;
  });

  return new Choices(element, {
    renderChoiceLimit: 5,
    shouldSort: false,
    callbackOnCreateTemplates: function(template) {
      return {
        item: function (styles, data) {
          return template(`
            <div
              class="${classNames(
                styles.item,
                data.highlighted ? styles.highlightedState : styles.itemSelectable),
                data.placeholder && styles.placeholder
              }"
              data-item
              data-id="${data.id}"
              data-value="${data.value}"
              ${data.active ? 'aria-selected="true"' : ''}
              ${data.disabled ? 'aria-disabled="true"' : ''}
            >
              ${data.customProperties || data.label}
            </div>
          `);
        },
        choice: function (styles, data) {
          return template(`
            <div
              class="${classNames(
                styles.item,
                styles.itemChoice,
                data.disabled ? styles.itemDisabled : styles.itemSelectable
              )}"
              data-select-text="${this.config.itemSelectText}"
              data-choice
              ${data.disabled
                ? 'data-choice-disabled aria-disabled="true"'
                : 'data-choice-selectable'
              }
              data-id="${data.id}"
              data-value="${data.value}"
              ${data.groupId > 0 ? 'role="treeitem"' : 'role="option"'}
            >
              ${data.customProperties || data.label}
            </div>
          `);
        },
      }
    }
  });
}

function setupCalendarEvents() {
  $('button.calendar-day').each(function() {
    const button = $(this);
    const content = button.next();
    content.remove();
    button.popover({
      content,
      html: true,
      trigger: 'focus',
      placement: 'bottom',
    });
  });
}

function setupLeavingWebsiteAlerts() {
  $('a[target="_blank"]').on('click', function (event) {
    event.preventDefault();
    const url = $(this).attr('href');
    const name = "Citizens Progressive Bank";
    const message = `You are leaving ${name} website and will be redirected to another site. ${name} makes no endorsements or claims about the accuracy or content of the information contained in these sites. The security and privacy policies on these sites may be different than those of ${name}.`;
    if (window.confirm(message)) {
      window.open(url, '_blank');
    }
  })
}

$(document).ready(function() {
  setupQuickLinks();
  setupCalendarEvents();
  setupLeavingWebsiteAlerts();
});

$('#search-modal').on('shown.bs.modal', function () {
  $('#search-modal-input').trigger('focus')
})